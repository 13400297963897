import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "../landing";
// import Landing from "../landing-light";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
        <Route index element={<Landing />} />
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
