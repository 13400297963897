import {
  UserGroupIcon,
  HeartIcon,
  LanguageIcon,
  GlobeAltIcon,
  ClipboardDocumentListIcon,
  BeakerIcon,
  HomeIcon,
  MapIcon,
  SparklesIcon,
  ScaleIcon,
  FaceSmileIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

const image1 = require("./home-hero-1.jpg");

const primaryFeatures = [
  {
    name: "Korean Medical Excellence",
    description:
      "Access world-renowned Korean hospitals and medical professionals known for their cutting-edge treatments and technology.",
    href: "/",
    icon: HeartIcon, // You may need to import this icon
  },
  {
    name: "Cultural Immersion",
    description:
      "Combine your medical journey with authentic Korean experiences, from traditional temples to modern K-pop culture.",
    href: "/",
    icon: GlobeAltIcon, // You may need to import this icon
  },
  {
    name: "Personalized Experience",
    description:
      "Enjoy tailored medical and travel plans designed to meet your unique health needs and preferences.",
    href: "/",
    icon: UserGroupIcon, // You may need to import this icon
  },
];

const secondaryFeatures = [
  {
    name: "Comprehensive Health Screenings",
    description:
      "Thorough medical check-ups using state-of-the-art technology.",
    icon: ClipboardDocumentListIcon,
  },
  {
    name: "Specialized Treatments",
    description:
      "Access to a wide range of medical specialties and cutting-edge treatments.",
    icon: BeakerIcon,
  },
  {
    name: "Luxury Accommodations",
    description:
      "Comfortable and high-quality lodging for your recovery and relaxation.",
    icon: HomeIcon,
  },
  {
    name: "Tailored Travel",
    description:
      "Visa application support and customized travel plans to explore your destination before or after treatment.",
    icon: MapIcon,
  },
  {
    name: "Multilingual Support",
    description:
      "Communication assistance to ensure you're comfortable throughout your journey.",
    icon: LanguageIcon,
  },
  {
    name: "Post-Treatment Care",
    description:
      "Continued support and follow-up care after your medical procedure.",
    icon: HeartIcon,
  },
];

// const footerNavigation = {
//   solutions: [
//     { name: "Hosting", href: "/" },
//     { name: "Data Services", href: "/" },
//     { name: "Uptime Monitoring", href: "/" },
//     { name: "Enterprise Services", href: "/" },
//   ],
//   support: [
//     { name: "Pricing", href: "/" },
//     { name: "Documentation", href: "/" },
//     { name: "Guides", href: "/" },
//     { name: "API Reference", href: "/" },
//   ],
//   company: [
//     { name: "About", href: "/" },
//     { name: "Blog", href: "/" },
//     { name: "Jobs", href: "/" },
//     { name: "Press", href: "/" },
//     { name: "Partners", href: "/" },
//   ],
//   legal: [
//     { name: "Claim", href: "/" },
//     { name: "Privacy", href: "/" },
//     { name: "Terms", href: "/" },
//   ],
//   social: [],
// };

const treatments = [
  {
    name: "Fertility & Maternity",
    description:
      "IVF, egg freezing, advanced reproductive technologies, and comprehensive post-partum care",
    icon: BeakerIcon,
  },
  {
    name: "Orthopedics",
    description:
      "Platelet-rich plasma therapy, stem cell treatments, and regenerative medicine for joints and soft tissues",
    icon: HeartIcon,
  },
  {
    name: "Cosmetic Surgery",
    description: "World-famous Korean plastic surgery and aesthetic procedures",
    icon: SparklesIcon,
  },
  {
    name: "Health Screenings",
    description: "Comprehensive Korean-style full-body check-ups",
    icon: ClipboardDocumentListIcon,
  },
  {
    name: "Dental Care",
    description: "Implants, cosmetic dentistry, and oral surgeries",
    icon: FaceSmileIcon,
  },

  {
    name: "Weight Loss",
    description:
      "Bariatric surgeries and medically supervised weight loss programs",
    icon: ScaleIcon,
  },
];

const koreaFeatures = [
  {
    name: "Advanced Medical Technology",
    description:
      "Access world-class facilities equipped with cutting-edge medical technology.",
    icon: BeakerIcon,
  },
  {
    name: "Expert Healthcare Professionals",
    description:
      "Receive care from highly skilled and internationally trained doctors.",
    icon: UserGroupIcon,
  },
  {
    name: "Competitive Pricing",
    description: "Experience world-class treatments at competitive prices.",
    icon: CurrencyDollarIcon,
  },
];

export default function Landing() {
  return (
    <div className="bg-gray-900">
      <main>
        {/* Hero section */}
        <div className="relative isolate overflow-hidden">
          <img
            src={image1}
            alt="Hero background"
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-60 -z-10"></div>

          <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-40 lg:flex lg:px-8 lg:pt-40">
            <div className="flex flex-col justify-center items-center">
              <div className="text-center">
                <h1 className="mt-10 text-4xl font-semibold tracking-tight text-white sm:text-6xl">
                  Experience World-Class Healthcare in Korea
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Vitality Excursions combines the best Korean medical expertise
                  with a fully-managed luxury experience, offering you a unique
                  opportunity to revitalize your health while exploring the Land
                  of the Morning Calm.
                </p>

                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Contact us for a free consultation today.
                </p>
              </div>
              <div className="w-full sm:w-1/2 mt-6">
                <form
                  action="https://formspree.io/f/mgeggdjz"
                  method="POST"
                  className="space-y-4"
                >
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Name"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Your message
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows={8}
                        name="comment"
                        id="comment"
                        placeholder="The more details you provide about your health goals and interests, the better we can tailor our assistance to your needs. We look forward to helping you on your journey to wellness in Korea."
                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="rounded-md bg-indigo-600 px-8 py-4 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-24 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-400">
                Comprehensive Care
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Our Treatment Offerings
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Explore our wide range of medical treatments provided by
                world-class specialists in state-of-the-art facilities.
              </p>
            </div>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {treatments.map((treatment) => (
                <div key={treatment.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                    <treatment.icon
                      className="h-5 w-5 flex-none text-indigo-400"
                      aria-hidden="true"
                    />
                    {treatment.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                    <p className="flex-auto">{treatment.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* Feature section */}
        <div className="mx-auto mt-24 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-400">
              Unparalleled Service
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              The Vitality Excursions Advantage
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Our comprehensive approach combines medical expertise, luxurious
              travel, and personalized care. Discover how we transform health
              journeys into unforgettable experiences.
            </p>
          </div>

          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {primaryFeatures.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="text-base font-semibold leading-7 text-white">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* Feature section */}
        <div className="mt-32 sm:mt-56">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-400">
                Custom care for every patient
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Your Health Journey, Our Expertise
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                From world-class medical treatments to luxurious recovery
                experiences, we provide everything you need for a successful
                medical tourism journey. Let us take care of the details while
                you focus on your health and well-being.
              </p>
            </div>
          </div>

          <div className="relative overflow-hidden pt-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <img
                src="/images/korea-city-horizon.jpg"
                alt="Korea horizon"
                className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
                width={2432}
                height={1442}
              />
              <div className="relative" aria-hidden="true">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
              </div>
            </div>
          </div>
          <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
            <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
              {secondaryFeatures.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-semibold text-white">
                    <feature.icon
                      className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>{" "}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        <div className="mx-auto mt-24 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-400">
              Discover Korea
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Why Choose Korea for Medical Tourism
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Korea offers a unique blend of cutting-edge medical care and rich
              cultural experiences, making it an ideal destination for your
              health journey.
            </p>
          </div>

          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {koreaFeatures.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="text-base font-semibold leading-7 text-white">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* CTA section */}
        <div className="relative isolate mt-32 px-6 py-32 sm:mt-56 sm:py-40 lg:px-8">
          <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1d4240dd-898f-445f-932d-e2872fd12de3"
                width={200}
                height={200}
                x="50%"
                y={0}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={0} className="overflow-visible fill-gray-800/20">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)"
            />
          </svg>
          <div
            className="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
              style={{
                clipPath:
                  "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Contact us for a free consultation
            </h2>

            <div className="text-left" id="contact-form">
              <form
                action="https://formspree.io/f/mgeggdjz"
                method="POST"
                className="space-y-4"
              >
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="you@example.com"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="comment"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Your message
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows={8}
                      name="comment"
                      id="comment"
                      placeholder="The more details you provide about your health goals and interests, the better we can tailor our assistance to your needs. We look forward to helping you on your journey to wellness in Korea."
                      className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-8 py-4 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6"></div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer aria-labelledby="footer-heading" className="relative">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
          <div className="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
            <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
              &copy; 2024 Vitality Excursions. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
